import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CareerContext from './CareerContext';

const CareerContextWrapper = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);

  const getAllJobs = () => {
    setLoading(true);
    axios
      .get(`https://www.stand8.io/.netlify/functions/getJobs`)
      .then(function (_response) {
        setAllJobs(_response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (allJobs.length === 0) {
      getAllJobs();
    }
  }, []);

  return (
    <CareerContext.Provider
      value={{
        loading,
        allJobs,
      }}>
      {children}
    </CareerContext.Provider>
  );
};

export default CareerContextWrapper;
