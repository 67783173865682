import "@fontsource/montserrat";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "react-dropdown/style.css";
import "rc-collapse/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import React from "react";
import CareerContextWrapper from "./src/context/CareerContextWrapper";

export const wrapRootElement = ({ element }) => (
  <CareerContextWrapper>{element}</CareerContextWrapper>
);
